import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { colors, device } from '@/layout/GlobalStyles';

const StyledNav = styled.nav`
    position: sticky;
    top: 0em;
    z-index: 1;
    background: linear-gradient(
        95.98deg,
        rgba(123, 97, 255, 0.5) 0.46%,
        rgba(37, 60, 216, 0.5) 99.44%
    );

    backdrop-filter: blur(32px);
    height: 65px;
    padding: 0em 1.5em;
    div:first-of-type {
        width: 107px;
        cursor: pointer;
        height: 64px;
    }
    ul {
        li {
            font-weight: 400;
        }
    }
    ul,
    .contact-button {
        display: none;
    }
    @media ${device.tablet} {
        height: 96px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .contact-button {
            color: #1c3a27;
            border: none;
            display: block;
            width: 120px;
            height: 48px;
            background: #f4f9f8;
            border-radius: 8px;
        }
        ul {
            color: ${colors.white};
            list-style-type: none;
            justify-content: space-around;
            margin-right: 2em;
            font-size: 0.8em;
            display: flex;
            li {
                margin: 0em 1em;
            }
        }
    }
    @media ${device.laptop} {
        ul {
            font-size: 1em;
            li {
                margin: 0em 2em;
            }
        }
    }
    @media ${device.desktop} {
        padding: 1em 0em;
        div:first-of-type {
            width: 180px;
            height: 110px;
        }
        ul {
            font-size: 1.3em;
            li {
                margin: 0em 3em;
            }
        }
    }
`;

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

export const Nav: React.FC = () => {
    return (
        <StyledNav>
            <button style={{ border: 'none', background: 'none' }} onClick={() => scrollToTop()}>
                <StaticImage
                    src="../../images/svgs/logo.svg"
                    alt="believe-in-smiles logo"
                    placeholder="none"
                    quality={100}
                />
            </button>
            <ul>
                <li>
                    <a style={{ color: `${colors.white}` }} href="#about-us">
                        About
                    </a>
                </li>
                <li>
                    <a style={{ color: `${colors.white}` }} href="#how-it-works">
                        How it Works
                    </a>
                </li>
                <li>
                    <a style={{ color: `${colors.white}` }} href="#become-a-member">
                        Become a Member
                    </a>
                </li>
                <li>
                    <a style={{ color: `${colors.white}` }} href="#testimonials">
                        Testimonials
                    </a>
                </li>
            </ul>
            <a style={{ color: `${colors.grey}` }} href="#contact-us">
                <button className="contact-button"> Contact Us </button>
            </a>
        </StyledNav>
    );
};
