import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';

const StyledTextBlurb = styled.section`
    text-align: center;
    > p {
        padding: 0em 1em;
        margin-bottom: 2em;
        line-height: 25.9px;
        color: ${colors.grey};
    }
    /* regular ipad view */
    @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
        > p {
            padding: 0em 4em 2em;
        }
    }
    /* smaller laptop devices/tablets (when screen flipped) */
    @media ${device.tablet} and (orientation: landscape) {
        > p {
            max-width: 900px;
            margin: 0 auto;
            padding: 0em 0em 4em;
        }
    }
    @media ${device.desktop} {
        > p {
            max-width: 1100px;
            font-size: 1.2em;
            line-height: 34px;
        }
    }
`;

export const TextBlurb: React.FC = ({ children }) => {
    return <StyledTextBlurb>{children}</StyledTextBlurb>;
};
