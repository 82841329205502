import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { TextBlurb } from '@/layout/Text-Blurb';
import { MainImage } from '@/layout/Main-Image';
import styled from '@emotion/styled';
import { device, colors } from '@/layout/GlobalStyles';

const StyledTestimonials = styled.section``;

const TestimonialPanels = styled.div`
    .scroll-container {
        overflow: none;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* everything from the 3rd down */
        .panel {
            background: linear-gradient(180deg, #e2eeec -5.54%, rgba(227, 239, 237, 0) 100%);
            border: none;
            box-sizing: border-box;
            border-radius: 16px;
            padding: 1em;
            height: 400px;
            margin: 1em;
        }
        .last-panel {
            align-self: flex-start;
            background: linear-gradient(95.98deg, #7b61ff 0.46%, #253cd8 99.44%);
            border-radius: 16px;
            height: 128px;
            margin: -2em 2em 4em;
            min-width: 211px;
            display: flex;
            cursor: pointer;
            align-items: flex-end;
            div {
                position: relative;
                bottom: 1em;
                left: 1em;
            }
        }
        span {
            font-weight: 600;
            color: ${colors.primary};
        }
    }
    /* regular ipad view */
    @media ${device.tablet} and (max-width: 1024px) and (orientation: portrait) {
        .scroll-container {
            max-width: 655px;
            margin: 0 auto;
            overflow: hidden;
            flex-wrap: wrap;
            display: flex;
            align-items: center;
        }
        .panel {
            min-width: 595px;
            max-width: 295px;
            max-height: 296px;
        }
        .last-panel {
            margin-left: 1em;
        }
    }
    /* small laptops */
    @media ${device.tablet} and (orientation: landscape) {
        .panel {
            min-width: 595px;
            max-width: 295px;
            max-height: 236px;
        }
        padding: 0em 0em 4em;
        .scroll-container {
            justify-content: center;
            flex-direction: row;
            align-items: center;
            max-width: 1225px;
            margin: 0 auto;
            .last-panel {
                margin: 1em 2em 0em;
                height: 236px;
                align-self: start;
            }
        }
    }
    @media ${device.laptopL} {
        padding: 0em 0em 4em;
        p {
            line-height: 24px;
        }
    }
    @media ${device.desktop} {
        padding: 0em 0em 4em;
        .scroll-container {
            max-width: 1525px;
            margin: 3em auto;
            span {
                font-size: 20px;
            }
            .last-panel {
                margin: 1em 2em 0em;
                height: 336px;
            }
            .panel {
                min-width: 800px;
                max-height: 350px;
            }
        }

        p {
            font-size: 1.1em;
            line-height: 28px;
        }
    }
`;

export const Testimonials: React.FC = () => {
    return (
        <StyledTestimonials>
            <MainImage buttonText="CONTACT US">
                <StaticImage
                    className="main-image"
                    src="../images/asian-family-smiling.jpeg"
                    alt="asian family smiling"
                    placeholder="none"
                    quality={100}
                />
            </MainImage>
            <TextBlurb>
                <h2 id="testimonials" style={{ margin: '1em 0em' }}>
                    Testimonials
                </h2>
            </TextBlurb>
            <TestimonialPanels>
                <div className="scroll-container">
                    <div className="panel">
                        <p>
                            Becoming a patient of Believe in Smiles in 2013 has had such a positive
                            impact on my life. For as long as I can remember I always had numerous
                            cavities resulting in pain and eventually loss of many teeth. After I
                            received my new teeth thru Believe in Smiles I am now not embarrassed to
                            smile or talk to people without thinking all they are doing is looking
                            at my teeth or lack of them. I am now able to eat whatever I want
                            without pain or worrying about breaking teeth. Thank you again Dr.
                            Chamberlin and staff for being compassionate and caring about everyone’s
                            smiles.
                        </p>
                        <span>- Lloyd Plum</span>
                    </div>
                    <div className="last-panel">
                        {' '}
                        <StaticImage
                            src="../images/leave-review.png"
                            alt="leave a review"
                            placeholder="none"
                            quality={100}
                        />
                    </div>
                </div>
            </TestimonialPanels>
        </StyledTestimonials>
    );
};
