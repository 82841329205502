import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';

const StyledScrolledButton = styled.div`
    position: absolute;
    bottom: 2em;
    div {
        margin: 0 auto;
        width: 100vw;
    }
    p {
        font-style: uppercase;
        /* margin: 0 auto; */
        font-weight: lighter;
        font-size: 36px;
        color: ${colors.white};
    }
    img {
        margin: 0 auto;

        /* margin: 0 auto; */
        width: 63px;
        height: 97px;
    }
    @media ${device.tablet} {
        position: absolute;
        right: 2em;
        bottom: 2.5em;
        p {
            min-width: 190px;
            text-transform: uppercase;
        }
        > div {
            width: 300px;
            display: flex;
            align-items: center;
            margin: 0 auto;
            > div {
                top: 0.75em;
                position: relative;
                transition: 0.2s ease-in;
                &:hover {
                    transform: translateY(5%);
                }
            }
        }
    }
`;

interface Props {
    buttonText: string;
}

export const ScrollButton: React.FC<Props> = ({ buttonText }) => {
    return (
        <StyledScrolledButton>
            <div>
                <p>{buttonText}</p>
                <StaticImage
                    src="../../images/svgs/down-arrow.svg"
                    alt="scroll down"
                    placeholder="none"
                    quality={100}
                />
            </div>
        </StyledScrolledButton>
    );
};
