import React from 'react';
import { GatsbyImage, ImageDataLike } from 'gatsby-plugin-image';
import { BubblePanel } from '@/layout/Bubble-Panel';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from '@/layout/GlobalStyles';

const panels = [
    {
        title: 'Theodore Chamberlain',
        text: 'Dr Theodore Chamberlain founded Believe In Smiles in 2013.',
        imageName: 'theodore-chamberlain',
    },
    {
        title: 'Bay Area Endodontics',
        text: 'Dr. Christopher Ross is one of the Bay Area’s top leading endodontists and has graciously offered his skills, expertise, and time to Believe in Smiles.',
        imageName: 'bay-area-endodontics',
    },
    {
        title: 'Grace Christian Fellowship',
        text: 'Grace Christian Fellowship is a Christian family church in Largo, Florida that aims to proclaim and portray the grace of God to the world.',
        imageName: 'grace-christian-fellowship',
    },
    {
        title: 'McDowell Orthodontics',
        imageName: 'mcdowell-orthodontics',
    },
    {
        title: 'Crawford Dental Labs',
        imageName: 'crawford-dental-labs',
    },
];

const StyledAboutUs = styled.section`
    > div {
        overflow: scroll;
    }
    .scroll-container {
        display: flex;
    }
    @media ${device.tablet} {
        padding: 0em 0em 4em;
    }
    @media ${device.laptop} {
        max-width: 1080px;
        margin: 0 auto;
        > div {
            overflow: hidden;
        }
        .scroll-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        padding: 2em 0em 2em;
    }
`;

export const AboutUs: React.FC = () => {
    const data = useStaticQuery(graphql`
        query photos {
            allFile(filter: { relativeDirectory: { eq: "about-us" } }) {
                edges {
                    node {
                        id
                        childImageSharp {
                            gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 300)
                        }
                        name
                    }
                }
            }
        }
    `);

    const images = data.allFile.edges;
    return (
        <StyledAboutUs>
            <h2 id="about-us" style={{ margin: '1em 0em', textAlign: 'center' }}>
                About <span>Us</span>
            </h2>
            <div>
                <div className="scroll-container">
                    {panels.map(panel => {
                        return (
                            <div key={panel.title}>
                                <BubblePanel>
                                    {images.map(
                                        (image: {
                                            node: {
                                                childImageSharp: {
                                                    gatsbyImageData: ImageDataLike | any;
                                                    //how to fix this???
                                                };
                                                name: string;
                                            };
                                        }) => {
                                            {
                                                if (image.node.name === panel.imageName) {
                                                    return (
                                                        <GatsbyImage
                                                            image={
                                                                image.node.childImageSharp
                                                                    .gatsbyImageData
                                                            }
                                                            alt={image.node.name}
                                                        />
                                                    );
                                                }
                                            }
                                        }
                                    )}
                                    <h3>{panel.title}</h3>
                                    <p>{panel.text}</p>
                                </BubblePanel>
                            </div>
                        );
                    })}
                </div>
            </div>
        </StyledAboutUs>
    );
};
