import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import heroImage from '../images/homepage-banner.jpeg';
import { colors, device } from '@/layout/GlobalStyles';

const StyledHero = styled.section`
    padding: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    /* object-fit: cover;
    background: url(${heroImage}), no-repeat; */
    > div:first-of-type {
        height: 100%;
        width: 100%;
        top: 0em;
        position: absolute;
        object-fit: cover;
    }
    /* panel */
    > div:last-of-type {
        background: linear-gradient(
            95.98deg,
            rgba(123, 97, 255, 0.5) 0.46%,
            rgba(37, 60, 216, 0.5) 99.44%
        );
        backdrop-filter: blur(15px);
        width: 80vw;
        height: 334px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        border-radius: 64px;
        /* logo */
        .logo {
            margin: 2em 0em 1em;
            min-height: 120px;
        }
        > h1 {
            padding: 1em 2em;
            color: ${colors.white};
        }
    }

    /* regular ipad view */
    @media ${device.tablet} and (max-width: 1024px) and (orientation: portrait) {
        align-items: start;
        height: 95vh;
        h1 {
            font-size: 39px;
            padding: 1em 2em;
        }
        > div:last-of-type {
            margin-top: 12em;
            padding-top: 2em;
            .logo {
                margin: 1em 0em -1em;
                width: 230px;
            }
        }
    }
    /* smaller laptop devices and tablet in landscape mode
 */
    @media ${device.tablet} and (max-width: 1024px) and (orientation: landscape) {
        height: 90vh;
        h1 {
            font-size: 32px;
            padding: 1em 2em;
        }
        > div:last-of-type {
            padding-top: 2em;
            .logo {
                margin: 1em 0em 0em;
                height: 180px;
                width: 300px;
            }
        }
    }
    @media ${device.laptop} {
        height: 95vh;
        h1 {
            font-size: 42px;
            padding: 0em;
            min-width: 432px;
            line-height: 78px;
        }
        > div:last-of-type {
            flex-direction: row;
            justify-content: space-between;
            padding: 0em;
            width: 960px;
            height: 326px;
            .logo {
                padding: 0em;
                margin: 0em 0em 0em 4em;
                height: 200px;
                min-width: 380px;
            }
        }
    }
    @media ${device.laptopL} {
        h1 {
            font-size: 48px;
            min-width: 432px;
            line-height: 78px;
        }
        > div:first-of-type {
            filter: blur(5px);
        }
        > div:last-of-type {
            width: 1060px;
            height: 366px;

            .logo {
                margin: 0em 0em 0em 2.5em;
                height: 245px;
                min-width: 420px;
            }
        }
    }
`;

export const Hero: React.FC = () => {
    return (
        <StyledHero>
            <StaticImage
                alt="pixelated squares"
                src="../images/homepage-banner.jpeg"
                placeholder="none"
                quality={100}
            />
            <div>
                <StaticImage
                    className="logo"
                    alt="believe-in-smiles logo"
                    src="../images/svgs/logo.svg"
                    placeholder="none"
                    quality={100}
                />
                <h1>Believe in Faith, Believe in Yourself</h1>
            </div>
        </StyledHero>
    );
};
