import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { colors, device } from '@/layout/GlobalStyles';

const StyledFooter = styled.footer`
    background: linear-gradient(95.98deg, #7b61ff 0.46%, #253cd8 99.44%);
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 0em;
    /* logo */
    /* div:first-child {
        margin: 1em 0em;
    } */
    div {
        width: 250px;
        height: 150px;
    }
    h4 {
        text-align: center;
        font-size: 24px;
        line-height: 38px;
    }
    ul {
        text-align: center;
        list-style: none;
        padding: 0em;
        li {
            margin: 1em 0em;
        }
    }
    .line {
        background: #179dd9;
        width: 300px;
        height: 1px;
    }
    span {
        font-size: 0.8em;
        padding: 2em 0em 0em;
    }
    @media ${device.laptop} {
        ul {
            display: flex;
            li {
                margin: 3em 2.5em;
            }
        }
        .line {
            width: 800px;
            margin-bottom: 2em;
        }
        span {
            font-size: 1em;
            font-weight: 400;
            letter-spacing: 0.5px;
            padding: 2em 0em 0em;
        }
    }
    @media ${device.desktop} {
        div {
            width: 300px;
            height: 180px;
        }
        ul {
            li {
                font-size: 1.2em;
            }
        }
        h4 {
            font-size: 32px;
        }
        .line {
            margin-bottom: 1.5em;
        }
        span {
            font-size: 1.2em;
        }
    }
`;
const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

export const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <button style={{ border: 'none', background: 'none' }} onClick={() => scrollToTop()}>
                <StaticImage
                    src="../../images/svgs/logo.svg"
                    placeholder="none"
                    quality={100}
                    alt="believe in smiles logo"
                />
            </button>
            <h4>Believe in Faith, Believe in Yourself</h4>
            <ul>
                <li>
                    <a style={{ color: `${colors.white}` }} href="#about-us">
                        About
                    </a>
                </li>
                <li>
                    <a style={{ color: `${colors.white}` }} href="#how-it-works">
                        How it Works{' '}
                    </a>
                </li>
                <li>
                    {' '}
                    <a style={{ color: `${colors.white}` }} href="#become-a-member">
                        Become a Member{' '}
                    </a>
                </li>
                <li>
                    <a style={{ color: `${colors.white}` }} href="#testimonials">
                        Testimonials
                    </a>
                </li>
            </ul>
            <div className="line"></div>
            <span>Believeinsmiles.org | All Rights Reserved. 2022. </span>
        </StyledFooter>
    );
};
