import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { TextBlurb } from '@/layout/Text-Blurb';
import { MainImage } from '@/layout/Main-Image';

export const BecomeMember: React.FC = () => {
    return (
        <>
            <TextBlurb>
                <MainImage buttonText="become a member">
                    <StaticImage
                        id="become-a-member"
                        className="main-image"
                        src="../images/blonde-girl-smiling.jpeg"
                        alt="blonde girl smiling"
                        placeholder="none"
                        quality={100}
                    />
                </MainImage>
                <h2 style={{ marginTop: '1em' }}>
                    become a <span>member</span>
                </h2>
                <p>
                    To become a member of Believe In Smiles is easy. All you have to do is have a
                    devotion to faith or wish to enhance your current awareness of your own faith.
                    As a participating dentist, you will not only enrich your individual
                    relationship with your faith, but you will be helping out those in need and
                    doing what we all enjoy most- creating beautiful healthy smiles and helping
                    others. Please email us with any questions you may have about the program.
                </p>
            </TextBlurb>
        </>
    );
};
