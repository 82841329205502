import React from 'react';
import styled from '@emotion/styled';
import { device } from '@/layout/GlobalStyles';
import { ScrollButton } from './Scroll-Button';

const StyledMainImage = styled.div`
    position: relative;
    .main-image {
        padding: 14em;
    }
    @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
        .main-image {
            padding: 0em;
            height: 50.1vh;
        }
    }
    @media ${device.tablet} and (orientation: landscape) {
        .main-image {
            padding: 0em;
        }
    }
    @media ${device.desktop} {
        .main-image {
            width: 100vw;
        }
    }
`;

interface Props {
    buttonText: string;
}

export const MainImage: React.FC<Props> = ({ children, buttonText }) => {
    return (
        <StyledMainImage>
            {children}
            <ScrollButton buttonText={buttonText} />
        </StyledMainImage>
    );
};
