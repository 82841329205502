import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { TextBlurb } from '@/layout/Text-Blurb';
import { MainImage } from '@/layout/Main-Image';

export const OurMission: React.FC = () => {
    return (
        <>
            <TextBlurb>
                <h2>
                    Our <span>Mission</span>
                </h2>
                <p>
                    Believe in Smiles, (BIS) is a nonprofit organization committed to enhancing the
                    faith of dentists, promoting oral hygiene, and helping individuals achieve
                    complete dental health and wellness. Its purpose is exclusively charitable and
                    educational.
                </p>
                <MainImage buttonText="ABOUT US">
                    <StaticImage
                        className="main-image"
                        src="../images/children-smiling.jpeg"
                        alt="children smiling"
                        placeholder="none"
                        quality={100}
                    />
                </MainImage>
            </TextBlurb>
        </>
    );
};
