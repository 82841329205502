import { Hero } from '@/Hero';
import { Nav } from '@/layout/Nav';
import { OurMission } from '@/Our-Mission';
import { AboutUs } from '@/About-Us';
import { HowItWorks } from '@/How-It-Works';
import { BecomeMember } from '@/Become-Member';
import { Testimonials } from '@/Testimonials';
import { ContactUs } from '@/Contact-Us';
import { Footer } from '@/layout/Footer';

const Home: Page = () => (
    <>
        <Nav />
        <Hero />
        <OurMission />
        <AboutUs />
        <HowItWorks />
        <BecomeMember />
        <Testimonials />
        <ContactUs />
        <Footer />
    </>
);

export default Home;
