import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { TextBlurb } from '@/layout/Text-Blurb';
import { MainImage } from '@/layout/Main-Image';

export const HowItWorks: React.FC = () => {
    return (
        <>
            <TextBlurb>
                <MainImage buttonText="how it works">
                    <StaticImage
                        id="how-it-works"
                        className="main-image"
                        src="../images/girl-smiling-on-beach.jpeg"
                        alt="girl smiling on beach"
                        placeholder="none"
                        quality={100}
                    />
                </MainImage>
                <h2 style={{ marginTop: '1em' }}>
                    HOW THE PROGRAM <span>works</span>
                </h2>
                <p>
                    Widely accepted among many religious organizations is the belief that internal
                    happiness is secured through one’s charitable giving to both community AND
                    faith-based organization. Believe in Smiles provides dentists with the ideal
                    opportunity to meet both requirements and ‘tithe’ by donating dental work and
                    improving the lives of others. Believe in Smiles links dentists to faith-based
                    organizations through direct communication and ongoing engagement. BIS helps
                    foster relationships with religious leaders such as Pastors, Preachers, and
                    Rabbis, by offering pro-bono dental work to members of their congregation.
                </p>
                <p style={{ marginTop: '-3em' }}>
                    This faith-based community can be of any religious affiliation and is
                    nondiscriminatory in nature, thus being an all-inclusive organization. The
                    relationship formed is three fold, beginning with the dentist continuing with
                    the pastor and ending with the patient (a member of the congregation). The
                    dentist directly interacts with the pastor who then introduces an ‘in need’
                    patient to the dentist that is willing to do pro-bono work on behalf of the
                    faith-based organization.
                </p>
            </TextBlurb>
        </>
    );
};
