import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { colors, device } from '@/layout/GlobalStyles';

const StyledBubblePanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    margin: 0em 2em;
    div {
        border-radius: 200px;
        max-width: 200px;
        max-height: 200px;
        img {
            border-radius: 200px;
            border: 4px solid #179dd9;
            box-sizing: border-box;
            width: 200px;
            height: 200px;
        }
    }
    h3 {
        padding: 0em 2.25em;
        line-height: 26px;
        text-align: center;
        color: ${colors.primary};
    }
    p {
        line-height: 26px;
        color: ${colors.grey};
        text-align: center;
    }
`;

export const BubblePanel: React.FC = ({ children }) => {
    return <StyledBubblePanel>{children}</StyledBubblePanel>;
};
