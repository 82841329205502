import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from '@/layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { TextBlurb } from '@/layout/Text-Blurb';
import { BubblePanel } from '@/layout/Bubble-Panel';

interface Props {
    color: string;
}

export const Button = styled.button<Props>`
    border: ${({ color }) =>
        (color === 'white' && `2px solid ${colors.white}`) ||
        (color === 'blue' && `2px solid ${colors.primary}`)};
    color: ${({ color }) =>
        (color === 'white' && `${colors.white}`) || (color === 'blue' && `${colors.primary}`)};
    box-sizing: border-box;
    font-weight: 700;
    padding: 1em 1.75em 1em;
    display: flex;
    font-size: 16px;
    justify-content: space-around;
    align-items: center;
    background: none;
    max-width: 200px;
    margin: 2em 0em 0em;
    border-radius: 64px;
    position: relative;
    span {
        margin-right: 1em;
        font-style: normal;
    }
`;

const Container = styled.section`
    padding-bottom: 4em;
    .flex-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    /* regular ipad */
    @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
        .flex-container {
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            align-items: center;
        }
    }
    /* small laptops and up */
    @media ${device.tablet} and (orientation: landscape) {
        .flex-container {
            margin: 0 auto;
            max-width: 1050px;
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            align-items: center;
        }
    }
`;

const ContactForm = styled.form`
    background: ${colors.primary};
    border-radius: 64px;
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 2em 2em 3em;
    label {
        margin: 1em 0em;
        color: ${colors.white};
    }
    input,
    textarea {
        border-radius: 5px;
        padding: 1em;
        border: none;
        background: #ffffff;
        border: 2.5px solid #ffffff;
    }
    @media ${device.tablet} and (orientation: landscape) {
        width: 428px;
        padding: 3em 4em 1em;
    }
`;

export const ContactUs: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <Container>
            <TextBlurb>
                <h2 id="contact-us">
                    Contact <span>Us</span>
                </h2>
            </TextBlurb>
            <div className="flex-container">
                <BubblePanel>
                    <h3 style={{ marginBottom: '0em' }}>Email</h3>
                    <a
                        target="__blank"
                        rel="noopener noreferrer"
                        href="mailto:office@chamberlaindental.com"
                    >
                        <p style={{ color: `${colors.primary}`, textDecoration: 'underline' }}>
                            office@chamberlaindental.com
                        </p>
                    </a>
                </BubblePanel>
                <ContactForm onSubmit={onSubmit}>
                    <label htmlFor="name">Name *</label>
                    <input name="name" id="name" type="text" placeholder="John Doe" required />
                    <label htmlFor="phone">Phone *</label>
                    <input
                        name="phone"
                        id="phone"
                        placeholder="(222) 222-222"
                        type="tel"
                        required
                    />
                    <label htmlFor="email">Email address *</label>
                    <input
                        name="email"
                        id="email"
                        placeholder="example@gmail.com"
                        type="email"
                        required
                    />
                    <label htmlFor="message">A Message for us</label>
                    <textarea name="message" id="message" placeholder="Write your message here" />
                    <Button disabled={submitState === 'submitting'} type="submit" color="white">
                        {' '}
                        <span>
                            {submitState === 'submitting' ? 'Sending...' : 'Get in Touch'}
                        </span>{' '}
                        <StaticImage
                            src="../images/svgs/arrow-right-white.svg"
                            placeholder="none"
                            quality={100}
                            alt="white arrow point right"
                        />
                    </Button>
                    <p
                        style={{
                            marginTop: '24px',
                            color: 'white',
                            transition: 'opacity .2s ease-in',
                            opacity: `${submitState === 'submitted' ? '1' : '0'}`,
                        }}
                    >
                        Thank You! Your message has been sent
                    </p>
                </ContactForm>
            </div>
        </Container>
    );
};
